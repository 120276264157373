import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Subtitle } from '../../ui/TypographyStyles';
import { MaxWidthWrapper } from '../../ui/utils';
import ParagraphMedium from '../../ui/ParagraphMedium';
import Button from '../../ui/BaseButton';

import Desktop from '../../images/pages/homepage/desktop.webp';
import Tablet from '../../images/pages/homepage/tablet.webp';
import Mobile from '../../images/pages/homepage/mobile.webp';

const Container = styled.section`
  background-color: #f4e4d8;
  padding-top: 100px;
  padding: 100px 40px 0;
  margin-bottom: 12px;
  height: 600px;
  background-image: url(${Desktop});
  background-repeat: no-repeat;
  background-size: 1440px;
  background-position-y: -140px;
  background-position-x: 102%;

  @media ${BREAKPOINTS.DESKTOPLARGE} {
    padding-top: 160px;
    height: 700px;
  }
  @media screen and (max-width: 1300px) {
    background-size: 1200px;
    background-position-x: 115%;
    background-position-y: -80px;
  }
  @media screen and (max-width: 1200px) {
    background-size: 1100px;
    background-position-x: 100%;
  }
  @media screen and (max-width: 1140px) {
    background-size: 1080px;
    background-position-x: 100%;
    background-position-x: 105%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 1024px;
    background-position-y: bottom;
    background-position-x: 60px;
  }
  @media screen and (max-width: 980px) {
    background-size: 1000px;
    background-position-y: -60px;
    background-position-x: 40px;
  }
  @media screen and (max-width: 950px) {
    background-image: url(${Tablet});
    background-size: auto 1100px;
    background-position-y: -260px;
    background-position-x: center;
    padding: 40px 20px 0;
  }
  @media ${BREAKPOINTS.MOBILE} {
    background-image: none;
    overflow-x: hidden;
    height: fit-content;
  }
`;

const TextWrapper = styled.div`
  max-width: 520px;
  margin-bottom: 40px;

  @media screen and (max-width: 950px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

const Heading = styled.h1`
  font-family: Gibson !important;
  font-size: 72px;
  font-weight: 600;
  line-height: 74px;
  letter-spacing: -0.5px;
  margin-bottom: 24px;

  @media screen and (max-width: 950px) {
    font-size: 44px;
    line-height: 46px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
`;

const Text = styled(Subtitle)`
  margin-bottom: 0;

  @media screen and (max-width: 950px) {
    line-height: 26px;
    text-align: center;
    padding: 0 40px;
  }
  @media ${BREAKPOINTS.MOBILESMALL} {
    padding: 0;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    justify-content: center;
  }
  @media screen and (max-width: 475px) {
    flex-direction: column;
  }
`;

const CTATel = styled(ParagraphMedium)`
  margin-bottom: 0;
  margin-left: 24px;

  @media screen and (max-width: 475px) {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const Tel = styled.a`
  color: ${COLORS.BLACK};
  font-weight: 700;
`;

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPagesHomepage(filter: { pageId: { eq: "homepage" } }) {
        nodes {
          heroHeader
          heroText
        }
      }
      dog: file(relativePath: { eq: "pages/homepage/dog.webp" }) {
        childImageSharp {
          fluid(maxWidth: 780, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dogMobile: file(
        relativePath: { eq: "pages/homepage/mobile-transparent.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const copy = data.allContentfulPagesHomepage.nodes[0];

  return (
    <Container>
      <MaxWidthWrapper>
        <TextWrapper>
          <Heading>{copy.heroHeader}</Heading>
          <Text>{copy.heroText}</Text>
        </TextWrapper>
        <CTAWrapper>
          <Button
            type='external'
            href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
            color={COLORS.ORANGE}
            css={css`
              width: fit-content;
            `}
          >
            Book Now
          </Button>
          <CTATel>
            Or call us at <Tel href='tel:(212) 933-9044'>212.933.9044</Tel>
          </CTATel>
        </CTAWrapper>
      </MaxWidthWrapper>
      <Img
        fluid={data.dogMobile.childImageSharp.fluid}
        css={css`
          display: none;
          @media ${BREAKPOINTS.MOBILE} {
            display: block;
            width: 105vw;
            height: 100%;
            position: relative;
            left: -30px;
          }
        `}
      />
    </Container>
  );
};

export default Hero;
