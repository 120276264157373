import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Uppercase } from '../../ui/TypographyStyles';
import { MaxWidthWrapper } from '../../ui/utils';
import Button from '../../ui/BaseButton';
import Heading4 from '../../ui/Heading4';
import ParagraphSmall from '../../ui/ParagraphSmall';

import ChatIcon from '../../images/icons/chat-icon.svg';
import DentalIcon from '../../images/icons/dental-icon.svg';
import LabIcon from '../../images/icons/lab-icon.svg';
import VaccineIcon from '../../images/icons/vaccine-icon.svg';

const Wrapper = styled.section`
  background-color: ${COLORS.SECONDARY_GRAY};
  padding: 80px 0;
  margin-bottom: 12px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 64px 0;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Heading = styled(Heading4)`
  margin: 24px 0 8px;
`;

const ServiceList = styled.ul`
  padding: 60px 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    justify-content: flex-start;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 40px 0;
  }
`;

const Service = styled.li`
  background: rgba(255, 255, 255, 0.4);
  padding: 24px 20px;
  max-width: 300px;
  flex-shrink: 0;
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${BREAKPOINTS.DESKTOPLARGE} {
    padding: 32px 32px 40px;
  }

  @media screen and (max-width: 1300px) {
    &:first-of-type {
      margin-left: 40px;
    }
    &:last-of-type {
      margin-right: 40px;
    }
  }
  @media ${BREAKPOINTS.TABLET} {
    &:last-of-type {
      margin-right: 0px;
      max-width: 340px;
      border-right: 40px solid ${COLORS.SECONDARY_GRAY};
    }
  }
  @media ${BREAKPOINTS.MOBILE} {
    max-width: 247px;
    &:first-of-type {
      margin-left: 20px;
    }
    &:last-of-type {
      max-width: 267px;
      border-right: 20px solid ${COLORS.SECONDARY_GRAY};
    }
  }
`;

const Icon = styled.div`
  background-size: 56px 56px;
  width: 56px;
  height: 56px;
  @media ${BREAKPOINTS.TABLET} {
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
  }
`;

const Telemedicine = styled(Icon)`
  background-image: url(${ChatIcon});
  background-size: 58px 58px;
  @media ${BREAKPOINTS.TABLET} {
    background-size: 42px 42px;
  }
`;
const Dental = styled(Icon)`
  background-image: url(${DentalIcon});
`;
const Diagnostic = styled(Icon)`
  background-image: url(${LabIcon});
`;
const Vaccinations = styled(Icon)`
  background-image: url(${VaccineIcon});
`;

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPagesHomepage(filter: { pageId: { eq: "homepage" } }) {
        nodes {
          services {
            services {
              title
              text
            }
          }
        }
      }
    }
  `);

  const copy = data.allContentfulPagesHomepage.nodes[0].services;

  return (
    <Wrapper>
      <MaxWidthWrapper
        css={css`
          @media screen and (max-width: 1300px) {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
          }
        `}
      >
        <Uppercase
          css={css`
            margin: 0 auto;
            width: fit-content;
          `}
        >
          Expert services
        </Uppercase>
        <ServiceList>
          <Service>
            <Telemedicine />
            <Heading>{copy.services[0].title}</Heading>
            <ParagraphSmall>{copy.services[0].text}</ParagraphSmall>
          </Service>
          <Service>
            <Dental />

            <Heading>{copy.services[1].title}</Heading>
            <ParagraphSmall>{copy.services[1].text}</ParagraphSmall>
          </Service>
          <Service>
            <Diagnostic />
            <Heading>{copy.services[2].title}</Heading>
            <ParagraphSmall>{copy.services[2].text}</ParagraphSmall>
          </Service>
          <Service>
            <Vaccinations />
            <Heading>{copy.services[3].title}</Heading>
            <ParagraphSmall>{copy.services[3].text}</ParagraphSmall>
          </Service>
        </ServiceList>
        <div
          css={css`
            margin: 0 auto;
            width: fit-content;
          `}
        >
          <Button
            type='external'
            href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
            color={COLORS.ORANGE}
          >
            Book Now
          </Button>
        </div>
      </MaxWidthWrapper>
    </Wrapper>
  );
};

export default Services;
