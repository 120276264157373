import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

import Button from '../../ui/BaseButton';
import { MaxWidthWrapper } from '../../ui/utils';
import { BREAKPOINTS, COLORS } from '../../ui/variables';

import CatIllustration from '../../images/graphics/illustration.svg';

const Container = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 60px 40px;
  margin-bottom: 12px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 40px;
  }
`;

const Wrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  background-image: url(${CatIllustration});
  width: 242px;
  height: 144px;
  order: 2;

  @media ${BREAKPOINTS.MOBILE} {
    width: 195px;
    height: 115px;
    background-size: 100%;
    background-repeat: no-repeat;
    order: 1;
  }
`;

const Div = styled.div`
  @media ${BREAKPOINTS.MOBILE} {
    order: 1;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled.div`
  font-family: Adonis !important;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 40px;
  width: 460px;

  a {
    text-decoration: underline;
    font-weight: 400;
    color: ${COLORS.BLACK};
    &:hover {
      color: ${COLORS.DARK_GRAY};
    }
  }
  p {
    margin-bottom: 0px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    width: 550px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    width: fit-content;
    text-align: center;
  }
`;

const CTAButton = styled(Button)`
  width: fit-content;

  @media ${BREAKPOINTS.MOBILE} {
    margin: 0 auto;
    align-self: center;
  }
`;

const Locations = () => {
  const data = useStaticQuery(graphql`
    query {
      copy: allContentfulPagesHomepage(filter: { pageId: { eq: "homepage" } }) {
        nodes {
          locationsText {
            locationsText
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <ImageContainer />
        <Div>
          <Text>
            <ReactMarkdown>
              {data.copy.nodes[0].locationsText.locationsText}
            </ReactMarkdown>
          </Text>
          <CTAButton type='internal' to='/locations' color={COLORS.ORANGE}>
            Locations
          </CTAButton>
        </Div>
      </Wrapper>
    </Container>
  );
};

export default Locations;
